import { MouseEvent, ReactNode } from 'react';

import { Link } from 'components/RouterLink';
import { getRelativeURL } from 'utils/url/getRelativeURL';
import { isExternalURL } from 'utils/url/isExternalURL';

import { ButtonOrLinkData } from './ButtonOrLinkData';

type OwnProps = {
  qaId?: string;
  className?: string;
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

type Props =
  | (ButtonOrLinkData & OwnProps)
  | (OwnProps & {
      /**
       * While usually `<ButtonOrLink type="..." ...>...</ButtonOrLink>` is a
       * most ergonomic option for most cases, sometimes we may have logic for
       * choosing the button or link data based on other variables.
       *
       * In those cases we'll usually have a useMemo() to produce the appropriate
       * data based on certain rules, then instead of spreading {...data} we can
       * do `<ButtonOrLink data={...}>...</ButtonOrLink>`.
       *
       * Spreading props is often a bit dangerous because it can mask problems.
       * By using `data={}` we have type-safety and avoid spreading props.
       */
      data: ButtonOrLinkData;
    });

/**
 * This component is a switch between the multiple types of link that we have
 *
 * It allows using the same style regardless of the target element being a button,
 * an external link, or a react-router link
 */
export function ButtonOrLink({
  qaId,
  className,
  children,
  onClick,
  ...otherProps
}: Props) {
  const data = 'data' in otherProps ? otherProps.data : otherProps;

  switch (data.type) {
    case 'inert':
      return (
        <div className={className} data-qa-id={qaId}>
          {children}
        </div>
      );
    case 'button':
      return (
        <button
          className={className}
          type="button"
          data-qa-id={qaId}
          onClick={onClick}
          disabled={data.disabled}
        >
          {children}
        </button>
      );
    case 'link-with-state':
      return (
        <Link
          className={className}
          to={data.to}
          state={data.state}
          data-qa-id={qaId}
          onClick={onClick}
        >
          {children}
        </Link>
      );
    case 'link': {
      const external = isExternalURL(data.href);

      if (data.fullPageReloadAnchor || data.target || external) {
        return (
          <a
            className={className}
            href={data.href}
            target={external ? '_blank' : data.target}
            data-qa-id={qaId}
            onClick={onClick}
            rel={external ? 'noopener noreferrer' : undefined}
          >
            {children}
          </a>
        );
      }

      return (
        <Link
          className={className}
          to={getRelativeURL(data.href)}
          data-qa-id={qaId}
          onClick={onClick}
        >
          {children}
        </Link>
      );
    }
  }
}
